import React from 'react';
import styled from 'styled-components';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
	ArcElement,
	BarElement
} from 'chart.js';

const Wrapper = styled.div`
  // height: 30px;
  // color: red;
  // text-align: center;
  // margin-bottom: 20px;
`;

const ChartWrapper = ({children}) => {
	
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
		ArcElement,
		BarElement
  );

	return (
		<Wrapper>
			{children}
		</Wrapper>
	)
}

export default ChartWrapper;
