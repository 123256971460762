import React, {useState, useEffect, useContext} from 'react';
import {useLocation, NavLink, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {CognitoContext} from '../../contexts/CognitoContext';

import {BLACKPEARL, BORDER, CHETWODEBLUE} from "@ozwol/webui";

import Sidebar from "./Sidebar";
import ButtonWithAlert from "./../ButtonWithAlert";

import ProjectsService from './../../services/ProjectsService';
import HotfoldersService from './../../services/HotfoldersService';
import AccountService from './../../services/AccountService';

import {adminControl, checkPermission} from './../../helpers';

const COLUMN_WIDTH = "72px";
const SIDE_WIDTH = "256px";


const Container = styled.div`
  & > div:last-child{
    left: ${props => !props.openSide && !props.fixSide ? "calc("+COLUMN_WIDTH+" - "+SIDE_WIDTH+")" : COLUMN_WIDTH};
  }
`;
const Column = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${COLUMN_WIDTH};
  height: 100%;
  z-index: 3;

  display: flex;
  flex-direction: column;

  flex-grow: 0;
  flex-shrink: 0;
  background: #E9EBF4;
  border-right: 1px solid ${BORDER}80;
  text-align: center;
  padding: 20px 4px 8px;
  justify-content: space-between;

  user-select: none;
`; 
const Toggle = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;

  color: ${BLACKPEARL};
  &:hover{
    color: ${CHETWODEBLUE};
  }
`;
const ColumnGroup = styled.div`
`;
const ColumnGroupSeparator = styled.div`
  padding: 10px;
  border-bottom: 1px solid #00141f66;
`;


const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px 6px;
  border-radius: 4px;
  margin-top: 15px;
  color: ${props => props.isActive ? CHETWODEBLUE : BLACKPEARL};
  border: 1px solid ${props => props.isActive ? BORDER : "transparent"};
  background: ${props => props.isActive ? "#ffffff" : "transparent"};
  box-shadow: ${props => props.isActive ? "0px 1px 2px #687FE239" : "none"};

  cursor: pointer;

  ${props => props.last ? "align-self: end;" : ""}

  &:hover{
    color: ${CHETWODEBLUE};
  }
  &:hover *{
    border-color: ${CHETWODEBLUE};
  }
`;
const ColumnItemIcon = styled.div`
  margin-bottom: -3px;
  & *{  
    font-size: 30px;
  }
`;
const ColumnItemText = styled.div`
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.3px
  position: relative;
  height: 10px;
  line-height: 10px;
`;
const Side = styled.div`
  position: absolute;
  top: 0px;
  left: ${props => props.fixSide ? COLUMN_WIDTH : "calc("+COLUMN_WIDTH+" - "+SIDE_WIDTH+")"};
  width: ${SIDE_WIDTH};
  height: 100%;
  z-index: 2;

  background: #F9F9FD;
  border-right: 1px solid ${BORDER}80;

  transition: all 200ms ease-in-out;
  user-select: none;
`;
const Avatar = styled.div`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  border: 2px solid ${props => props.active ? CHETWODEBLUE : BLACKPEARL};
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: middle;

  &::after{
    display: inline-block;
    text-align: center;
    content: "${props => props.label}";
    font-size: 18px;
    margin-top: 2px;
    margin-left: 1px;
  }
`;

const Hoverer = ({ section, onSetFixSide }) => {
  const [fixSide, setFixSide] = useState(true);
  const [openSide, setOpenSide] = useState(false);
  const [defaultProject, setDefaultProject] = useState(null);
  const [hotfolders, setHotfolders] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [period, setPeriod] = useState(null);
  const {cognito} = useContext(CognitoContext);
  const [user, setUser] = useState(null);
  

  useEffect(() => {
    if(checkPermission("project", "list")){
      ProjectsService.list().then((response) => {
        setDefaultProject(response.result[0]);    
      });
    }
    // if(checkPermission("hotfolders", "list")){
      HotfoldersService.list().then((response) => {
        setHotfolders(response);    
      });
    // }
    if(cognito && cognito.groups && !cognito.groups.includes("OzwolCoworker")){ 
      AccountService.getUser().then((res) => {
        setUser(res);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [cognito]);
  



  return (
        <Container 
          fixSide={fixSide} 
          openSide={openSide} 
          onMouseEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenSide(true);
          }} 
          onMouseLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenSide(false);
          }} 
        >
          <Column>
            <ColumnGroup>
              <Toggle onClick={() => {setFixSide(!fixSide); onSetFixSide(!fixSide); setOpenSide(false);}}>
                <span className="material-symbols-outlined">{fixSide ? "menu_open" : "menu"}</span>
              </Toggle>
              <NavLink to={"/overall/overview"} exact="false">
                {() => (
                  <ColumnItem isActive={pathname.includes('/overall')}>
                    <ColumnItemIcon><span className="material-symbols-outlined">mountain_flag</span></ColumnItemIcon>
                    <ColumnItemText>Overall</ColumnItemText>
                  </ColumnItem>
                )}
              </NavLink>
              {defaultProject && checkPermission("project", "list") ? 
                <NavLink to={"/project/"+(defaultProject ? defaultProject.uuid : null)+"/dashboard"} exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/project/')}>
                      <ColumnItemIcon><span className="material-symbols-outlined">business_center</span></ColumnItemIcon>
                      <ColumnItemText>Projects</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              :
                <NavLink exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/project/')} style={{opacity: "0.1"}}>
                      <ColumnItemIcon><span className="material-symbols-outlined">business_center</span></ColumnItemIcon>
                      <ColumnItemText>Projects</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              }
              <NavLink to={"/account"} exact="false">
                {() => (
                  <ColumnItem isActive={pathname.includes('/account')}>
                  <ColumnItemIcon><Avatar label={cognito.ParsedJwtToken["given_name"][0]} active={pathname.includes('/account')} /></ColumnItemIcon>
                    <ColumnItemText>Account</ColumnItemText>
                  </ColumnItem>
                )}
              </NavLink>
              {checkPermission("coworker", "list") && ((cognito && cognito.groups && cognito.groups.includes("OzwolCoworker")) || (user && user.currentPlan.teamAllowed)) ? 
                <NavLink to={"/team"} exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/team')}>
                      <ColumnItemIcon><span className="material-symbols-outlined">groups</span></ColumnItemIcon>
                      <ColumnItemText>Team</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              : null }
              {defaultProject && checkPermission("project", "list") && hotfolders && hotfolders.length > 0 ? 
                  <NavLink to={"/workspace/"+(defaultProject ? defaultProject.uuid : null)+"/hotfolders"} exact="false">
                    {() => (
                      <ColumnItem isActive={pathname.includes('/workspace')}>
                        <ColumnItemIcon><span className="material-symbols-outlined">cloud_upload</span></ColumnItemIcon>
                        <ColumnItemText>Workspace</ColumnItemText>
                      </ColumnItem>
                    )}
                  </NavLink>
                : null
                  // <NavLink exact="false">
                  //   {() => (
                  //     <ColumnItem isActive={pathname.includes('/workspace/')} style={{opacity: "0.1"}}>
                  //       <ColumnItemIcon><span className="material-symbols-outlined">cloud_upload</span></ColumnItemIcon>
                  //       <ColumnItemText>Workspace</ColumnItemText>
                  //     </ColumnItem>
                  //   )}
                  // </NavLink>
                }
              {adminControl(cognito) ? 
                <NavLink to={"/insider"} exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/insider')}>
                    <ColumnItemIcon><span className="material-symbols-outlined">shield_person</span></ColumnItemIcon>
                      <ColumnItemText>Insider</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              : null}

              <ColumnGroupSeparator />
              <ButtonWithAlert
                title="Are you sure you want to logout?"
                onConfirm={() => navigate("/auth/logout")}
              >            
                <ColumnItem>
                  <ColumnItemIcon><span className="material-symbols-outlined">power_settings_new</span></ColumnItemIcon>
                  <ColumnItemText>Logout</ColumnItemText>
                </ColumnItem>
              </ButtonWithAlert>
            </ColumnGroup>
            
              
          </Column>
          <Side fixSide={fixSide}>
            <Sidebar section={section} />
          </Side>
        </Container>
  )
}

export default Hoverer;
