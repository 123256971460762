import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import { getFormattedDate, isAccountGoingToBeClosed, checkPermission } from "./../helpers";

import {CHETWODEBLUE, OzButton} from "@ozwol/webui";

import PeriodTimelineGrid from './../components/PeriodTimelineGrid';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Left = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
`;
const Right = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-left: 10px;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
`;

const TimeLabel = styled.div`
  font-size: 13px;
  color: #303030;
  margin-bottom: 8px;
`;
const TimeValue = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #303030;
`;

const PlanInfo = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  `;
const PlanLabel = styled.span`
  color: ${CHETWODEBLUE};
  margin-right: 5px;
  font-weight: 500;
`;
const NextPlanLabel = styled.span`
  color: #707070;
  margin-right: 10px;
  font-weight: 700;
`;
const PlanTag = styled(Link)`
  color: ${CHETWODEBLUE};
  font-weight: 500;
  text-decoration: underline !important;
  & *{
    cursor: pointer;
  }
`;
const ChangePlanNotification = styled.div`
  display: inline-block;
  color: #FF4848;
  background: #FF484815;
  font-size: 12px;
  font-weight: 700;
  padding: 2px 7px;
  margin-top: 4px;
  border-radius: 4px;
`;



const ReferencePeriodTimeline = ({
  period = null,
  user = null,
  plansPage = false,  
  showCta = true
}) => {
  if(period && period.currentPlan && period.currentPlan.planType === "COMPLIMENTARY"){
    
    return (<>
      <Wrapper>
        <Left>
          <PlanInfo>
            <PlanLabel>
              {new Date(period.endDate) > new Date() ? 
                "Current plan"
              :
                "Period plan"
              }:</PlanLabel><PlanTag to="/account/plans">{period.currentPlan.name}</PlanTag>
          </PlanInfo>
        </Left>
      </Wrapper>
    </>);

  }else if(period && period.currentPlan && period.currentPlan.planType === "PAID"){
    
    if(period.currentPlan.periodDuration !== "MONTHLY"){
      let start = new Date(period.startDate);
      let end = new Date(period.endDate);

      return (<>
        <Wrapper>
          <Left>
            <TimeLabel>Period start</TimeLabel>
            <TimeValue>{getFormattedDate(start)}</TimeValue>
          </Left>
          <Center>
            <PeriodTimelineGrid start={start} end={end} space={0} />
          </Center>
          <Right>
            <TimeLabel>Period end</TimeLabel>
            <TimeValue>{getFormattedDate(end)}</TimeValue>
          </Right>
        </Wrapper>
        
        <hr/>
        <Wrapper>
          <Left>
            <PlanInfo>
              <PlanLabel>{new Date(period.endDate) > new Date() ? 
                "Current plan"
              :
                "Period plan"
              }:</PlanLabel><PlanTag to="/account/plans">{period.currentPlan.name}</PlanTag>
            </PlanInfo>
          </Left>
          <Center>
            {plansPage && user && (!user.nextPlan || user.nextPlan.uuid !== user.currentPlan.uuid) && new Date(period.endDate) > new Date() ?
              <ChangePlanNotification>All plan changes will take effect from the NEXT RENEWAL</ChangePlanNotification>
            : null }
          </Center>
          <Right>
            {user ? 
              <PlanInfo>
                {user.status === "OPERATIVE" && user.accountToBeClosed === true && showCta ?
                   <Link to="/account/profile"><OzButton size="small" variant={"silvertree-o"}>Reopen account</OzButton></Link>
                : user.status === "OPERATIVE" && user.accountToBeClosed === false && !user.nextPlan && showCta ?
                  <Link to="/account/plans"><OzButton size="small" variant={"silvertree-o"}>Set next plan</OzButton></Link>
                : user.nextPlan && (user.nextPlan.uuid !== user.currentPlan.uuid) ?
                  <>
                    <NextPlanLabel>Next plan: </NextPlanLabel> <PlanTag to="/account/plans">{user.nextPlan.name}</PlanTag>
                  </>
                : 
                  null
                }
              </PlanInfo>
            : null}
          </Right>
        </Wrapper>
      </>);
    }else{
      let start = new Date(period.startDate);
      let end = new Date(period.endDate);

      return (<>
        <Wrapper>
          <Left>
            <TimeLabel>Period start</TimeLabel>
            <TimeValue>{getFormattedDate(start)}</TimeValue>
          </Left>
          <Center>            
            <PeriodTimelineGrid start={start} end={end} space={3} recharges={period.recharges} isAccountGoingToBeClosed={isAccountGoingToBeClosed(period, user)} />            
          </Center>
          <Right>
            <TimeLabel>Period end</TimeLabel>
            <TimeValue>{getFormattedDate(end)}</TimeValue>
          </Right>
        </Wrapper>
        {checkPermission("subPlan", "list") ? 
          <>
            <hr/>
            <Wrapper>
              <Left>
                <PlanInfo>
                  <PlanLabel>{new Date(period.endDate) > new Date() ? 
                    "Current plan"
                  :
                    "Period plan"
                  }:</PlanLabel><PlanTag to="/account/plans">{period.currentPlan.name}</PlanTag>
                </PlanInfo>
              </Left>
              <Center>
                {plansPage && user && (!user.nextPlan || user.nextPlan.uuid !== user.currentPlan.uuid) && new Date(period.endDate) > new Date() ?
                  <ChangePlanNotification>All plan changes will take effect from the NEXT RENEWAL</ChangePlanNotification>
                : null }
              </Center>
              <Right>
                {user ? 
                  <PlanInfo>
                    {user.status === "OPERATIVE" && user.accountToBeClosed === true && showCta ?
                      <Link to="/account/profile"><OzButton size="small" variant={"silvertree-o"}>Reopen account</OzButton></Link>
                    : user.status === "OPERATIVE" && !user.accountToBeClosed === false && !user.nextPlan && showCta ?
                      <Link to="/account/plans"><OzButton size="small" variant={"silvertree-o"}>Set next plan</OzButton></Link>
                    : user.nextPlan && (user.nextPlan.uuid !== user.currentPlan.uuid) ?
                      <>
                        <NextPlanLabel>Next plan: </NextPlanLabel> <PlanTag to="/account/plans">{user.nextPlan.name}</PlanTag>
                      </>
                    : 
                      null
                    }
                  </PlanInfo>
                : null}
              </Right>
            </Wrapper>
          </>
        : null}
      </>);
    }
    
  }else{
    return (<></>);
  }
}

export default ReferencePeriodTimeline;
