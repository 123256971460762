import React, {useState, useEffect} from 'react';
import styled from 'styled-components';


import {SILVERTREE, MUSTARD, BITTERSWEET, SPANISHGREY, OzContainer, OzRow, OzCol, OzCard, OzTable} from '@ozwol/webui';


import UsersService from '../../services/UsersService';
// import UsagesService from '../../services/UsagesService';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import PeriodTimelineGrid from '../../components/PeriodTimelineGrid';

import {formatError, getFormattedDate} from './../../helpers';


const TableWrapper = styled.div`
  overflow-x: auto;
  
  & > table{
    width: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
  }
  & table tr td:nth-child(4),
  & table tr td:nth-child(5),
  & table tr td:nth-child(6),
  & table tr td:nth-child(7){
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > table tr td:nth-child(6) > *{
    min-width: 300px;
  }
`;

// const CardGroup = styled.div`
//   &>*:not(:first-child){
//     border-top-left-radius: 0px;
//     border-top-right-radius: 0px;
//     border-top: 3px solid ${GHOSTWHITE};
//   }
//   &>*:not(:last-child){
//     border-bottom-left-radius: 0px;
//     border-bottom-right-radius: 0px;
//   }
// `;


// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 40px;
//   margin-bottom: 40px;
// `;


const CardPhrase = styled.div`

  display: flex;
  align-items: center;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;

  display: flex;
  align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
`;


const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${props => props.color};
  margin: 0 auto;
`;

const InsiderRenewals = ({refScrollerPage}) => {
  const [users, setUsers] = useState([]);
  const [periods, setPeriods] = useState([]);

  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    UsersService.adminList({"limit": 0}).then((response) => {
      setUsers(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    UsersService.adminListPeriods({"limit": 0}).then((response) => {
      setPeriods(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    
  }, []);

  return (
    <OzContainer size="extra">
      <Meta title={"Renewals"} />
      <PageHeader
        breadcrumbsIcon="price_change"
        breadcrumbsText={["Renewals"]}
        refScrollerPage={refScrollerPage}
      />
      <HealthyWrapper error={fetchError} loading={!users}>
        {true ?
          <OzRow>
            <OzCol>
            
              
              <OzCard
                height="100%"
                headerLeft={
                  <CardHeaderTitle 
                    title="Periods"
                  />
                }
                headerRight={
                  <CardPhrase>
                    <CardPhraseText>
                      Number of owners
                      <CardPhraseSuperNumber>
                        {periods.length}
                      </CardPhraseSuperNumber>
                    </CardPhraseText>
                  </CardPhrase>
                }
              >
                <TableWrapper>
                  <OzTable
                    columns={[
                      "Status",
                      "Company",
                      "Plan",
                      "Expire days",
                      "Start period",
                      "",
                      "End period"
                    ]}
                    values={periods.map((item, i) => (({values: [
                      
                      users.filter(val => val.uuid === item.ownerUuid).length > 0 ?
                        users.filter(val => val.uuid === item.ownerUuid).map(user =>
                          <Dot key={user.status} color={
                            user.status === "OPERATIVE" && user.accountToBeClosed === false ? SILVERTREE : 
                            user.status === "OPERATIVE" && user.accountToBeClosed === true ? MUSTARD : 
                            user.status === "SUSPENDED" ? BITTERSWEET : 
                            user.status === "CLOSED" ? SPANISHGREY : 
                            null
                          } />
                        )
                      : null,
                      users.filter(val => val.uuid === item.ownerUuid).length > 0 ?
                        users.filter(val => val.uuid === item.ownerUuid).map(user =>
                          user.company
                        )
                      : "-",
                      item.currentPlan ? item.currentPlan.name : "-",
                      item.endDate ? Math.round(((new Date(item.endDate) - new Date())/ (1000 * 60 * 60 * 24)))+1 : "-",
                      getFormattedDate(item.startDate),
                      item.endDate ? 
                        <PeriodTimelineGrid 
                          space={0}
                          showToday={false}
                          start={item.startDate} 
                          end={item.endDate} 
                        />
                      : null,
                      item.endDate ? getFormattedDate(item.endDate) : "-"
                    ]})))}
                  />
                </TableWrapper>
              </OzCard>
            </OzCol>
          </OzRow>
        : null }
      </HealthyWrapper>
    </OzContainer>
  )
}

export default InsiderRenewals;
