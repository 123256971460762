
import React, {useState, useContext} from 'react';
import { useImageSize } from 'react-image-size';
import { TransformWrapper, TransformComponent, MiniMap } from "react-zoom-pan-pinch";
// import {useParams} from 'react-router-dom';
// import axios from 'axios';
import styled from 'styled-components';
import {OverlayContext} from './../../../contexts/OverlayContext';

import { SILVERTREE, OzButton } from '@ozwol/webui'; //CHETWODEBLUE

import OverlayFullscreen from './../../../components/OverlayFullscreen';
import ColorPicker from '../../../components/ColorPicker';
// import ReactImageZoom from 'react-image-zoom';

// import InnerImageZoom from 'react-inner-image-zoom';
// import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  // color: ${props => props.isActive ? SILVERTREE : "#ffffff"};
  opacity: ${props => props.isActive ? 1 : 0.33};
  flex-grow: 0;
  flex-shrink: 0;

  cursor: pointer;
  width: 64px;
  height: 64px;

  &:hover{
    opacity: 1;
  }
`;
const ItemIcon = styled.div`
  margin-bottom: -3px;
  & *{  
    font-size: 30px;
  }
`;

const ItemPicker = styled.div`
  margin: 3px;
`;
const ItemText = styled.div`
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.3px
  position: relative;
  height: 10px;
  line-height: 10px;
`;

const Counter = styled.div`
  color: #ffffff;
  width: 100%;
  text-align: center;
`;
const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;  
`;
const CurrentPath = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
`;

const CurrentPathIcon = styled.div`
  width: 26px;
  height: 26px;
  margin-right: -3px;
  color: ${"#ffffff"}; //CHETWODEBLUE
  margin-bottom: -1px;

  & span{
    display: block;
  }

`;

const FileName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0px 10px;
`;
const FileInfo = styled.div`
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 10px;
`;



const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MinimapWrapper = styled.div`
  border: 1px solid #D5D9ED54;
  position: absolute;
  top: 5px;
  right: 5px;

  & .rzpp-preview {
    border-color: ${SILVERTREE} !important;
  }
`;

const ImageWrapper = styled.div`
  // border: 1px solid red;
  display:inline-block;
  max-width: 100%;
  max-height: 100%;

  ${props => props.showZoom ? `
    cursor: zoom-in;
  `: null }

  ${props => props.showTransparency ? `
    & img {
      background-color: ` + props.bgcolor + `;
      background-size: 20px 20px;
      background-image: 
        linear-gradient(45deg, transparent 25%, transparent 25%, 
                        transparent 75%, transparent 75%, transparent),
        linear-gradient(45deg, transparent 25%, transparent 25%, 
                        transparent 75%, transparent 75%, transparent),
        linear-gradient(45deg, #FFFFFF40 25%, transparent 25%, 
                        transparent 75%, #FFFFFF40 75%, #FFFFFF40),
        linear-gradient(45deg, #FFFFFF40 25%, transparent 25%, 
                        transparent 75%, #FFFFFF40 75%, #FFFFFF40);
            background-position: 0 0, 10px 10px, 10px 0, 0 10px;
    }
  `: null }
  
  & .react-transform-wrapper{
    display: block;
    overflow: visible;
  }
  

`;
const Image = styled.img`
// positionn: relative;
  max-width: calc(100vw - 128px);
  max-height: calc(100vh - 128px);
  width: auto;
  height: auto;
  display: block;
`;

const Arrow = styled.div`
  position: absolute;
  top: calc(50% - 13px);
  ${props => props.prev ? "left" : "right"}: 10px;
  opacity: 0.33;
  &:hover{
    opacity: 1;
  }
  width: 26px;
  height: 26px;
  color: #fff;
  cursor: pointer;

  & span{
    display: block;
  }
`;


const HSeparator = styled.div`
  border-top: 1px solid #D5D9ED54;
  width: 100%;
  height: 1px;
`;
const VSeparator = styled.div`
  border-left: 1px solid #D5D9ED54;
  width: 1px;
  height: 100%;
`;

const ButtonIcon = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px;
  & > span{
    font-size: 20px;
  }
`;

const OverlayPreview = ({
  downloadUrl, 
  filePath, 
  // folderPath, 
  allFilesPaths,
  onNav
}) => {
    const {removeOverlay} = useContext(OverlayContext);
    const [dimensions] = useImageSize(downloadUrl);
    const [showZoom, setShowZoom] = useState(false);
    const [showTransparency, setShowTransparency] = useState(true);
    const [bgcolor, setBgcolor] = useState("#4A4A4A");


    return (
        <OverlayFullscreen 
            mainSidebar={<Bar>
              <Item isActive>
                <ItemIcon><span className="material-symbols-outlined">visibility</span></ItemIcon>
                <ItemText>Check</ItemText>
              </Item>
              {/* <Item>
                <ItemIcon><span className="material-symbols-outlined">draw</span></ItemIcon>
                <ItemText>Edit</ItemText>
              </Item> */}
            </Bar>}
            subSidebar={<Bar>
                {/* <Item isActive>
                  <ItemIcon><span className="material-symbols-outlined">image</span></ItemIcon>
                  <ItemText>Single</ItemText>
                </Item>
                <Item>
                  <ItemIcon><span className="material-symbols-outlined">gallery_thumbnail</span></ItemIcon>
                  <ItemText>Thumb</ItemText>
                </Item>
                <Item>
                  <ItemIcon><span className="material-symbols-outlined">grid_on</span></ItemIcon>
                  <ItemText>Gallery</ItemText>
                </Item>                
                <HSeparator/>  */}
                <Item isActive={showZoom} onClick={() => setShowZoom(!showZoom)}>
                  <ItemIcon><span className="material-symbols-outlined">search</span></ItemIcon>
                  <ItemText>Zoom</ItemText>
                </Item>
                <HSeparator/> 
                {/* <Item>
                  <ItemIcon><span className="material-symbols-outlined">colorize</span></ItemIcon>
                  <ItemText>Color pick</ItemText>
                </Item>
                <HSeparator/> 
                <Item>
                  <ItemIcon><span className="material-symbols-outlined">grid_4x4</span></ItemIcon>
                  <ItemText>Grids</ItemText>
                </Item>
                <Item>
                  <ItemIcon><span className="material-symbols-outlined">table_eye</span></ItemIcon>
                  <ItemText>Guidelines</ItemText>
                </Item>
                <HSeparator/>  */}
                <Item isActive={showTransparency} onClick={() => setShowTransparency(!showTransparency)}>
                  <ItemIcon><span className="material-symbols-outlined">transition_fade</span></ItemIcon> 
                  <ItemText>Transp.</ItemText>
                </Item>
                <HSeparator/>
                <Item isActive>
                  <ItemPicker>
                    <ColorPicker 
                      value={bgcolor} 
                      height={"26px"}
                      onChange={(color) => setBgcolor(color)} 
                    />
                  </ItemPicker> 
                  <ItemText>BG color</ItemText>
                </Item>
            </Bar>}
            content={
              <Content>
                {allFilesPaths.length > 1 ?
                  <Arrow
                    prev
                    onClick={() => onNav(
                      allFilesPaths.indexOf(filePath)-1 > -1 ?
                        allFilesPaths[allFilesPaths.indexOf(filePath)-1]
                      :
                        allFilesPaths[allFilesPaths.length - 1]
                    )}
                  >
                    <span className="material-symbols-outlined">arrow_back_ios</span>
                  </Arrow>
                : null}              
                  <ImageWrapper showZoom={showZoom} showTransparency={showTransparency} bgcolor={bgcolor}>
                    {showZoom ? 
                      <TransformWrapper 
                        // onZoomStart={() => setZooming(true)}
                        // onPanningStop={() => setZooming(false)}
                      >
                        <MinimapWrapper>
                          <MiniMap width={150} height={150}><Image src={downloadUrl} alt={"Preview "+filePath} /></MiniMap>
                        </MinimapWrapper>
                        <TransformComponent>
                          <Image src={downloadUrl} alt={"Preview "+filePath} /> 
                        </TransformComponent>
                      </TransformWrapper>



                      
                    : 
                      <Image src={downloadUrl} alt={"Preview "+filePath} /> 
                    }
                  </ImageWrapper>                  
                {allFilesPaths.length > 1 ?
                  <Arrow
                    next
                    onClick={() => onNav(
                      allFilesPaths.indexOf(filePath)+1 < allFilesPaths.length ?
                        allFilesPaths[allFilesPaths.indexOf(filePath)+1]
                      :
                        allFilesPaths[0]
                    )}
                  >
                    <span className="material-symbols-outlined">arrow_forward_ios</span>
                  </Arrow>
                : null}
              </Content>
            }
            bottomSidebar={<Counter>{allFilesPaths.indexOf(filePath)+1}/{allFilesPaths.length}</Counter>}
            bottomBar={
              <BottomBar>
                <CurrentPath>
                  <CurrentPathIcon><span className="material-symbols-outlined">folder_open</span></CurrentPathIcon>
                  {filePath.split('/')[filePath.split('/').length - 2]}</CurrentPath>
                <VSeparator/>
                <FileName>
                  <OzButton size="small" noMinWidth variant="push-jet" onClick={() => {
                    navigator.clipboard.writeText(filePath.split('/')[filePath.split('/').length - 1]);
                  }}>
                    <ButtonIcon><span className="material-symbols-outlined">content_copy</span></ButtonIcon>
                  </OzButton>
                  {filePath.split('/')[filePath.split('/').length - 1]}
                </FileName>
                <VSeparator/>
                <FileInfo>{dimensions ? dimensions.width + " x " + dimensions.height : null}  </FileInfo>
              </BottomBar>
            }
            fnClose={() => removeOverlay("preview")}
        />
    )
}

export default OverlayPreview;
