
import React from 'react';
// import {useParams} from 'react-router-dom';
// import axios from 'axios';
import styled from 'styled-components';

import { JET, OzLogo } from '@ozwol/webui';


const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
  align-items: center;
  background: ${JET};


  display: grid;
  grid-template-columns: 64px 64px auto;
  grid-template-rows: 64px auto 64px;
  align-items: stretch;
  align-content: stretch; 

`;
const Menu = styled.div`
  grid-column-start: 1;
  grid-column-end: span 1;

  border-right: 1px solid #D5D9ED22;
  border-bottom: 1px solid #D5D9ED22;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
const Topbar = styled.div`
  grid-column-start: 2;
  grid-column-end: span 2;
  border-bottom: 1px solid #D5D9ED22;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;
const MainSidebar = styled.div`
  grid-column-start: 1;
  grid-column-end: span 1;
  border-right: 1px solid #D5D9ED22;
//  overflow-x: hidden;
//  overflow-y: auto;
`;
const SubSidebar = styled.div`
  width: 64px;
  grid-column-start: 2;
  grid-column-end: span 1;
  border-right: 1px solid #D5D9ED22;
  // overflow-y: auto;
  // overflow-x: visible;
`;
const Content = styled.div`
  grid-column-start: ${props => props.hasSubSidebar ? 3 : 2};
  grid-column-end: span ${props => props.hasSubSidebar ? 1 : 2};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
//  overflow-x: hidden;
//  overflow-y: auto;
`;
const BottomSidebar = styled.div`
  border-right: 1px solid #D5D9ED22;
  grid-column-start: 1;
  grid-column-end: span 2;
  border-top: 1px solid #D5D9ED22;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;
const Bottombar = styled.div`
  grid-column-start: 3;
  grid-column-end: span 1;
  border-top: 1px solid #D5D9ED22;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;
const UiIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 64px;
  height: 64px;
  cursor: pointer;
  // margin-bottom: -3px;
  & *{  
    font-size: 30px;
  }
`;

const OverlayFullscreen = ({
  mainSidebar = null, 
  subSidebar = null, 
  bottomSidebar = null, 
  bottomBar = null, 
  content = null, 
  fnMenu = null, 
  fnClose = null
}) => {
  return (
    <Wrapper>
      <Menu>
        {fnMenu ?
          <UiIcon onClick={fnMenu}><span className="material-symbols-outlined">menu</span></UiIcon>
        : null }
      </Menu>
      <Topbar>
        <OzLogo variant={"inverse"} height="18px"/>
        <UiIcon onClick={fnClose}><span className="material-symbols-outlined">close</span></UiIcon>
      </Topbar>
      <MainSidebar>{mainSidebar}</MainSidebar>
      {subSidebar ? 
        <SubSidebar>{subSidebar}</SubSidebar>
      : null }
      <Content hasSubSidebar={subSidebar ? true : false}>
        {content}
      </Content>
      <BottomSidebar>
        {bottomSidebar}
      </BottomSidebar>
      <Bottombar>
        {bottomBar}        
      </Bottombar>
    </Wrapper>
  )
}

export default OverlayFullscreen;
