import React from 'react';
import styled from 'styled-components';
import { getArrayFromInterval, isSameDate } from "./../helpers";

import {SILVERTREE, CHETWODEBLUE, BITTERSWEET} from "@ozwol/webui";

const TimelineGrid = styled.div`
  display: grid;
  gap: ${props => props.space}px;
  grid-template-columns: repeat(${props => props.size},1fr);
  grid-template-rows: 1fr;

`;
const TimelineDay = styled.div`
  position: relative;
  background: ${props => props.color};
  color: ${props => props.color};
  height: 3px;
  min-width: 1px;
`;
const RechargeInfo = styled.div`
  position: relative;
  margin-top: -8px;
  
  & > span{
    position: relative;
    font-size: 18px;
    z-index: 3;
  }
  &:before{
    position: absolute;
    top: 8px;
    left: calc(calc(100% - 12px) / 2);
    width: 12px;
    height: 3px;
    content: "";
    display: block;
    background: #ffffff;
  }
`;
const RechargeInfoCounter = styled.div`
  color: #000000;
  font-size: 10px;
`;
const TodayTag = styled.div`
  position: absolute;
  width: 50px;
  left: 50%;
  top: -25px;
  margin-left: -25px;
  text-align: center;
  color: ${SILVERTREE};
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
`;



const PeriodTimelineGrid = ({
  start = null,
  end = null,
  space = 3,  
  recharges = null,
  isAccountGoingToBeClosed = false,
	showToday = true
}) => {
	let today = new Date(Date.now());
  return (<>
            <TimelineGrid size={getArrayFromInterval(start, end).length} space={space}>
              {getArrayFromInterval(start, end).map(day => 
                <TimelineDay
                  key={day}
                  color={
                    isSameDate(day, today, "d") ? SILVERTREE : //today
                    new Date(day).valueOf() < new Date().valueOf() ? CHETWODEBLUE : //past
                    (isAccountGoingToBeClosed ? BITTERSWEET : "#EBEBEB") //future
                  }
                >
                  {/* {new Date(day).getDate()}<br/> */}
                  {showToday && isSameDate(day, today, "d") ? 
                    <TodayTag>TODAY</TodayTag> 
                  : null}
                  {recharges && recharges.filter(recharge => isSameDate(day, recharge.createDatetime, "d")).length > 0 ?
                    <RechargeInfo>
                      <span className="material-symbols-outlined">nearby</span>
                      <RechargeInfoCounter>{recharges.filter(recharge => isSameDate(day, recharge.createDatetime, "d")).length}</RechargeInfoCounter>
                    </RechargeInfo>
                  : null}
                </TimelineDay>
              )}

            </TimelineGrid>
      </>);
}

export default PeriodTimelineGrid;
