import React, {useState, useEffect, useRef, useContext} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {CognitoContext} from '../../contexts/CognitoContext';

import {CHETWODEBLUE, SILVERTREE, LILAC, OzLoading, OzButton, OzTooltip, OzTable, OzContainer, OzRow, OzCol, OzCard, OzProjectTag, OzChartPie, OzChartLine} from "@ozwol/webui";

import AccountService from '../../services/AccountService';
import ProjectsService from '../../services/ProjectsService';

import Meta from '../../components/Meta';
import ReferencePeriodTimeline from '../../common/ReferencePeriodTimeline';
import PeriodTotal from '../../chunks/PeriodTotal';
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import MegaTooltip from '../../components/MegaTooltip';

import {checkPermission, getArrayFromInterval, formatError, getFormattedMonth, convertNumber, convertSize, getFormattedDate, getFormattedTime, getWeekDay, isSameDate, usageToGraph} from '../../helpers';

const CardPhraseCollapsed = styled.div`
  min-width: 160px;
  text-align: right;
  display: flex;
  justify-content: end;
  height: 100%;
`;

const CardPhrase = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;
  position: relative;
  top: 2px;

  display: flex;
  // align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
  position: relative;
  top: -2px;
`;

const MegaBadgeCnt = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const MegaBadge = styled.div`
  background: #ffffff;
  border: 2px solid ${props => props.variant === "today" ? SILVERTREE+"60" : LILAC};
  color: #303030;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 15px 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
`;

const RechargeIcon = styled.div`
  color: ${CHETWODEBLUE};
  margin-top: 2px;
  margin-bottom: -2px;
  & > span{
    font-size: 20px;
  }
`;
const RechargeIconMini = styled.span`
  color: ${CHETWODEBLUE};
  position: relative;
  top: 3px;
  & > span{
    position: relative;
    z-index: 3;
    font-size: 16px;
  }
  &:before{
    position: absolute;
    top: 8px;
    left: calc(calc(100% - 12px) / 2);
    width: 12px;
    height: 3px;
    content: "";
    display: block;
    background: #ffffff;
  }
`;

const PlanDot = styled.div`
  display: inline-block;
  background-color: ${SILVERTREE};
  width: 11px;
  height: 11px;
  border-radius: 3px;
`;

const BarWrapper = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  
  background-color: #EBEBEB;
`;
const BarFiller = styled.div`
  width: ${props => props.width}%;
  border: 1px solid ${props => props.color};
  background-color: ${props => props.color}33;
  &:not(:last-child){
    // border-right: 1px solid #fff;
  }

  &:hover{
    background-color: ${props => props.color}99;
  }
`;
const NoLog = styled.div`
  text-align: center;
  padding: 50px;
  font-size: 12px;
  font-weight: 400;
`;

const LabelComponent = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: -8px;
`;
const ChartContent = styled.div`
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0, -50%);
  text-align: center;
  width: 90%;
  padding: 0 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;
const ChartUsed = styled.div`
  color: #303030;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
`;
const ChartUsedOf = styled.div`
  font-size: 12px;
`;
// const ChartTotal = styled.div`
//   color: #30303099;
//   font-size: 12px;
//   font-weight: 400;
// `;

const Detail = styled.div`
  text-transform: uppercase;
  color: ${CHETWODEBLUE};
  
  font-size: 12px;
`;
const ScrollRef = styled.div`
  scroll-margin: 70px;
`;
const ChartXLabel = styled.div`
  line-height: 14px;
  display: flex;
  justify-content: center;
`;


const CreditsDashboard = styled.div`
  display: flex;
  flex-direction: row;
`;
const CreditsDashboardLeft = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(100% - 185px);
  & > * {
    width: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;    
  }
`;
const CreditsDashboardRight = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 185px;
  & > * {
    width: 100%;
    padding: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;   
    border-left: 0px;
  }
`;
const CreditsGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${props => props.slot},1fr);
`;
const CreditsGridBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px 0px;
  text-align: right;
  &:not(:first-child){
    border-top: 1px solid #d5d9ed;
  }

  background: ${props => props.important ? LILAC : "#ffffff"};
  color: ${props => props.important ? CHETWODEBLUE : "#303030"};
`;
const CreditsGridLabel = styled.div`
  // border: 1px solid lime;
  font-size: 11px;
`;
const CreditsGridValue = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  margin-top: 1px;
`;


const Overview = ({refScrollerPage}) => {
  const [user, setUser] = useState(null);
  const [period, setPeriod] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(-1);
  const [periodHistory, setPeriodHistory] = useState(null);
  const [usage, setUsage] = useState(null);
  const {cognito} = useContext(CognitoContext);

  const [projects, setProjects] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  
  const scrollToProjects = useRef();
  const [collapsedProjects, setCollapsedProjects] = useState(false);
  
  const scrollToCredits = useRef();
  const [collapsedCredits, setCollapsedCredits] = useState(false);
  const [loading, setLoading] = useState(true);

  // const [isDeletingAccount, setDeletingAccount] = useState(false); /* TODO Valorizzare */
  // const [isDeletingAccount] = useState(false); /* TODO Valorizzare */

  useEffect(() => {
    if(checkPermission("period", "read") && checkPermission("period", "list")){
      if(period === null || selectedPeriod !== period.sequenceNumber){
        AccountService.getPeriod({
          "includeRecharges": true
        }, selectedPeriod).then((response1) => {
          setPeriod(response1);
          setSelectedPeriod(response1.sequenceNumber);
          if(response1.currentPlan){   
            if(response1.startDate){
              AccountService.listUsages({
                "unit": "HOURS",
                "startDate": response1.startDate,
                "endDate": response1.currentPlan.planType === "PAID" ? response1.endDate : null
              }).then((response2) => {
                if(response2 && response2.result){
                  setUsage(response2.result);
                  setLoading(false);
                }  
              }).catch((error) => {
                setFetchError(formatError(error));
              }); 
            }
          }
          
        }).catch((error) => {
          setFetchError(formatError(error));
        }); 
      }
    }else{
      setLoading(false);
    }
  }, [period, selectedPeriod]);

  useEffect(() => {
    
    if(checkPermission("period", "read") && checkPermission("period", "list")){
      if(!cognito.groups.includes("OzwolCoworker")){
        AccountService.getUser().then((response) => {
          setUser(response);
        }).catch((error) => {
          setFetchError(formatError(error));
        }); 
      }
      if(checkPermission("period", "list")){
        AccountService.listPeriods().then((response) => {
          if(response && response.result){
            setPeriodHistory(response.result.slice(0, 6).reverse());
          }
        }).catch((error) => {
          setPeriodHistory(null);
          setFetchError(formatError(error));
        });
      }
    }
    if(checkPermission("project", "list")){
      ProjectsService.list().then((response3) => {
        let projects = response3.result;
        setProjects(projects);    
      }).catch((error) => {
        setFetchError(formatError(error));
      }); 
    }
  }, [cognito]);

  return (
    <OzContainer size="extra">
      <Meta title={"Overview"} />
      <PageHeader
        breadcrumbsIcon="mountain_flag"
        breadcrumbsText={["Overview", "Overall"]}
        refScrollerPage={refScrollerPage}
        anchors={[
          checkPermission("project", "list") ? {
            "icon": "business_center",
            "text": "Projects",
            "ref": scrollToProjects,
            "callback": () => {setCollapsedProjects(null); setTimeout( ()=> { setCollapsedProjects(false);},1)}
          } : null,
          checkPermission("period", "read") && checkPermission("period", "list") ? {
            "icon": "toll",
            "text": "Credits",
            "ref": scrollToCredits,
            "callback": () => {setCollapsedCredits(null); setTimeout( ()=> { setCollapsedCredits(false);},1)}
          } : null
        ]}
      />
      <HealthyWrapper error={fetchError} loading={loading}>
        {checkPermission("period", "read") && checkPermission("period", "list") ? 
          <>
            <OzRow>
              {period && periodHistory && periodHistory.length > 1 ? periodHistory.map((val, i) => 
                <OzCol widthmd={2} key={i}>
                  <OzButton
                    width="100%"
                    variant={selectedPeriod === val.sequenceNumber ? "silvertree-o" : "push-black"}
                    onClick={() => setSelectedPeriod(val.sequenceNumber)}
                  >
                    {new Date(val.endDate) > new Date() ?
                      "Current"
                    :
                      getFormattedDate(val.startDate, false) + " - " + getFormattedDate(val.endDate, false)
                    }
                  </OzButton>
                </OzCol>
              ) : null }
            </OzRow>
            <br/>
            <OzRow>
              {period ?
                <OzCol>
                  <OzCard
                    headerLeft={
                      <CardHeaderTitle title="Reference period" />
                    }
                  >
                    <ReferencePeriodTimeline
                      period={period}
                      user={user}
                    />
                  </OzCard>
                </OzCol>
              : <OzLoading /> }
              {period && projects && projects.length > 0 ?
                <OzCol widthmd="6">    
                  <CreditsDashboard>
                    <CreditsDashboardLeft>
                      <OzCard
                        height="100%"
                        headerLeft={
                          <>
                            <CardHeaderTitle title={"Credits usage " + (usage ? 
                              Math.round(
                                ((usage.map(item => item.scriptCredits + item.storageCredits + item.transferCredits).reduce((prev, curr) => prev + curr, 0) ) * 100 / period.creditsRecharged) *10
                              ) /10
                            : 0) + "%"}  />
                          </>
                        }
                      >                
                        <center>
                          <OzChartPie
                            fnFormatValue={(val) => convertNumber(val)}
                            values={projects.map((item, i) => ({
                              id: item.uuid,
                              label: item.name,
                              color: item.color,
                              value: usage ? (usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].scriptCredits + usage.filter(p=> p.projectUuid === item.uuid)[0].storageCredits + usage.filter(p=> p.projectUuid === item.uuid)[0].transferCredits) : 0) * 100 / period.creditsRecharged : 0
                            }))}
                          >
                            <ChartContent>
                              <ChartUsed>
                                {period ? convertNumber(period.creditsConsumed) : 0}
                              </ChartUsed>
                              <ChartUsedOf>of {convertNumber(period.creditsRecharged)}</ChartUsedOf>
                              {/* <ChartTotal>
                                {convertNumber(period.creditsRecharged)}
                              </ChartTotal> */}
                            </ChartContent>
                          </OzChartPie>
                        </center>
                      
                      </OzCard>
                    </CreditsDashboardLeft>
                    <CreditsDashboardRight>
                      <OzCard
                        height="100%"
                      > 
                        <CreditsGrid slots={period.currentPlan.planType === "PAID" ? 4 : 2}>
                          <CreditsGridBox>
                            <CreditsGridLabel>Plan included credits</CreditsGridLabel>
                            <CreditsGridValue>{convertNumber(period.currentPlan.credits)}</CreditsGridValue>
                          </CreditsGridBox>
                          {period.currentPlan.planType === "PAID" ?
                            <>
                              <CreditsGridBox>
                                <CreditsGridLabel>Roll-over credits</CreditsGridLabel>
                                <CreditsGridValue>{convertNumber(period.creditsRollOver)}</CreditsGridValue>
                              </CreditsGridBox>
                              <CreditsGridBox>
                                <CreditsGridLabel>Recharges ({period.rechargesCount})</CreditsGridLabel>
                                <CreditsGridValue>{convertNumber(period.rechargesCount * period.currentPlan.rechargeCredits)}</CreditsGridValue>
                              </CreditsGridBox>
                            </>
                          : null }
                          <CreditsGridBox important>
                            <CreditsGridLabel>Total credits</CreditsGridLabel>
                            <CreditsGridValue>{convertNumber(period.creditsRecharged)}</CreditsGridValue>
                          </CreditsGridBox>
                        </CreditsGrid>
                      </OzCard>
                    </CreditsDashboardRight>
                  </CreditsDashboard> 
                </OzCol>
              : null }
              {projects && projects.length > 0 ?
                <OzCol widthmd="3">       
                  <OzCard
                    height="100%"
                    headerLeft={
                      <>
                        <CardHeaderTitle title={<><OzTooltip>Average use over the current period</OzTooltip> Storage in use</>} /> 
                      </>
                    }
                  >
                    <MegaBadgeCnt>
                      <MegaBadge>{period ? convertSize(period.storageInUse) : 0}</MegaBadge>
                      <br/>
                      <br/>
                      <BarWrapper>
                        {usage ? 
                          projects.map((item, i) => (usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].storageInUse) : 0) * 100 / (usage.map(item => item.storageInUse).reduce((prev, curr) => prev + curr, 0) ) ? (
                            <BarFiller key={i} color={item.color} width={(usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].storageInUse) : 0) * 100 / (usage.map(item => item.storageInUse).reduce((prev, curr) => prev + curr, 0) ) ? (usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].storageInUse) : 0) * 100 / (usage.map(item => item.storageInUse).reduce((prev, curr) => prev + curr, 0) ) : 0}>
                              <MegaTooltip
                                title="Storage in use"
                                subtitle={item.name}
                                value={convertSize(usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].storageInUse) : 0)}
                              />
                            </BarFiller>
                          ) : null) 
                        : null}
                      </BarWrapper>
                    </MegaBadgeCnt>
                  </OzCard>
                </OzCol>
              : null }
              {projects && projects.length > 0 ?
                <OzCol widthmd="3">          
                  <OzCard
                    height="100%"
                    headerLeft={
                      <>
                        <CardHeaderTitle title={<><OzTooltip>Only transfer in download is charged; transfer in upload is free</OzTooltip> Data transfer consumed</>} />            
                      </>
                    }
                  >
                    <MegaBadgeCnt>
                      <MegaBadge>{period ? convertSize(period.transferConsumed) : 0}</MegaBadge>
                      <br/>
                      <br/>
                      <BarWrapper>
                        {usage ? 
                          projects.map((item, i) => (usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].transferConsumed) : 0) * 100 / (usage.map(item => item.transferConsumed).reduce((prev, curr) => prev + curr, 0) ) ? (
                            <BarFiller key={i} color={item.color} width={(usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].transferConsumed) : 0) * 100 / (usage.map(item => item.transferConsumed).reduce((prev, curr) => prev + curr, 0) ) ? (usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].transferConsumed) : 0) * 100 / (usage.map(item => item.transferConsumed).reduce((prev, curr) => prev + curr, 0) ) : 0} >
                            <MegaTooltip
                              title="Data transfer consumed"
                              subtitle={item.name}
                              value={convertSize(usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? (usage.filter(p=> p.projectUuid === item.uuid)[0].transferConsumed) : 0)}
                            />
                          </BarFiller>
                          ) : null)
                        : null}
                      </BarWrapper>
                        </MegaBadgeCnt>
                  </OzCard>
                </OzCol>
              : null }
            </OzRow>
            <br/>
          </>
        : null}
        <OzRow>          
          {checkPermission("project", "list") ? 
            <>
              { projects ?
                <OzCol>
                <ScrollRef ref={scrollToProjects} />      
                  <OzCard
                    height="100%"
                    collapsible={true}
                    collapsed={collapsedProjects}
                    headerLeft={<CardHeaderTitle title="Projects overview" />}
                    headerRight={
                      <CardPhrase>
                        <CardPhraseText>
                          Number of projects
                          <CardPhraseSuperNumber>
                            {projects.length}
                          </CardPhraseSuperNumber>
                        </CardPhraseText>
                      </CardPhrase>
                    }
                  >
                    <OzTable
                    columns={[
                      "Project",
                      "Assets processed today",
                      "Assets processed",
                      "Script credits",
                      "Storage",
                      "Storage credits",
                      "Data transfer",
                      "Data transfer credits",
                  //    "Operations"
                    ]}
                    values={projects.map((item, i) => (({values: [
                      <Link to={"/project/" + item.uuid + "/dashboard"}>
                        <OzProjectTag
                          name={item.name}
                          color={item.color}
                          direction={"horizontal"}
                        />
                      </Link>,
                      usage && usage.filter(p=> p.projectUuid === item.uuid).length > 0 && usage.filter(p=> p.projectUuid === item.uuid)[0].days.filter(day => isSameDate(day.date, new Date(), "d")).length > 0 ? convertNumber(usage.filter(p=> p.projectUuid === item.uuid)[0].days.filter(day => isSameDate(day.date, new Date(), "d"))[0].assetsProcessed) : "-",
                      usage && usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? convertNumber(usage.filter(p=> p.projectUuid === item.uuid)[0].assetsProcessed) : "-",
                      usage && usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? convertNumber(usage.filter(p=> p.projectUuid === item.uuid)[0].scriptCredits) : "-",
                      usage && usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? convertSize(usage.filter(p=> p.projectUuid === item.uuid)[0].storageInUse) : "-",
                      usage && usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? convertNumber(usage.filter(p=> p.projectUuid === item.uuid)[0].storageCredits) : "-",
                      usage && usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? convertSize(usage.filter(p=> p.projectUuid === item.uuid)[0].transferConsumed) : "-",
                      usage && usage.filter(p=> p.projectUuid === item.uuid).length > 0 ? convertNumber(usage.filter(p=> p.projectUuid === item.uuid)[0].transferCredits) : "-",
                  //    "???"
                    ]})))}/>
                  </OzCard>
                </OzCol>
              : <OzLoading /> }
            </>
          : null }

          {checkPermission("period", "read") && checkPermission("period", "list") ? 
            <>
              {period && period.currentPlan.planType === "PAID" ? 
                <>
                  {period.recharges && period.currentPlan ?
                    <OzCol>          
                      <OzCard
                        height="100%"
                        headerLeft={
                          <CardHeaderTitle 
                            title="Recharges" 
                            subtitle={<>{period.recharges.length} recharge in this period</>}
                          />
                        }
                        headerRight={
                          <CardPhrase>
                            <CardPhraseText>
                              Total sum of recharges
                              <CardPhraseSuperNumber>
                                {period.recharges.length > 0 ? convertNumber(period.recharges.map(item => item.creditsRecharged).reduce((a, b) => a + b, 0),0) : 0}
                              </CardPhraseSuperNumber>
                            </CardPhraseText>
                          </CardPhrase>
                        }
                        footerRight={
                          <Link to={"/overall/recharges"}>
                            <Detail>Detail</Detail>
                          </Link>
                        }
                      >

                        {period && period.recharges && period.recharges.length > 0 ?
                          <OzTable
                            columns={[
                              "",
                              "Date",
                              "Time",
                              <center>Credits</center>,
                              <center>Cost</center>
                            ]}
                            values={period.recharges.slice(-5).map(item => ({values: [
                              <RechargeIcon><span className="material-symbols-outlined">nearby</span></RechargeIcon>,
                              getFormattedDate(item.createDatetime),
                              getFormattedTime(item.createDatetime, false),
                              <center>{convertNumber(item.creditsRecharged)}</center>,
                              <center>{convertNumber(item.price)+"€"}</center>
                            ]}))}
                          />
                        :
                          <NoLog>No automatic charging during this period.</NoLog>
                        }
                      </OzCard>
                    </OzCol>
                  : <OzLoading /> }
                </>
              : null }
            </>
          : null}
          {checkPermission("period", "read") && checkPermission("period", "list") ? 
            <>
              {period && period.currentPlan.planType === "PAID" ? 
                <>
                  {period.currentPlan ?
                    <OzCol>
                      <PeriodTotal
                        numRecharges={period.recharges && period.recharges.length > 0 ? period.recharges.length : 0}
                        totRecharges={period.recharges && period.recharges.length > 0 ? period.recharges.map(item => item.price).reduce((a, b) => a + b, 0) : 0}
                        planName={period.currentPlan ? period.currentPlan.name : null}
                        planPrice={period.currentPlan ? period.currentPlan.price : 0}
                        isDeletingAccount={period.currentPlan.planType === "PAID" && (user && !user.nextPlan)}
                      />
                    </OzCol>
                  : <OzLoading /> }
                </>
              : null }
            </>
          : null}
          {checkPermission("period", "read") && checkPermission("period", "list") ? 
            <>
              {period && period.currentPlan ?
                <OzCol>
                  <ScrollRef ref={scrollToCredits} />
                  <OzCard
                    height="100%"
                    collapsible={true}
                    collapsed={collapsedCredits}
                    headerLeft={
                      <CardHeaderTitle 
                        title="Plan credits usage" 
                        subtitle={<>Usage since {getFormattedDate(period.startDate)}</>}
                      />
                    }
                    headerCenterCollapsed={
                      usage && usage.length > 0 ?
                        <OzChartLine
                          height={"36px"}
                          fnFormatY={(val) => ""}
                          fnFormatValue={(val) => ""}
                          style={{
                            xAxis:{
                              showLabels: false
                            },
                            yAxis:{
                              showLabels: false
                            },
                            yLines:{
                              color: "transparent"
                            },
                            lines:{
                              color: CHETWODEBLUE,
                              size: 1
                            },
                            dots:{
                              borderColor: "white",
                              borderSize: 3,
                              color: CHETWODEBLUE,
                              hoverColor: CHETWODEBLUE,
                              size: 6
                            }
                          }}        
                          values={usageToGraph(period, usage, true, "credits").map((item) => ({
                            label: <ChartXLabel>{isSameDate(item.date, new Date(), "d") ? "TODAY" : new Date(item.date).getDate()}<br/>{getWeekDay(item.date)[0]}</ChartXLabel>,
                            value: item.value,
                            style: isSameDate(item.date, new Date(), "d") ? {
                              labelColor: SILVERTREE,
                              barColor: SILVERTREE+"26",
                              barColorHover: SILVERTREE+"aa"
                            } : new Date(item.date) > new Date() ? {
                              // background: "#ffffff"
                            } : null
                          }))}
                        />
                      : null
                    }
                    headerRight={
                      <CardPhrase>
                        <CardPhraseText>
                          Total credits usage over the {new Date(period.endDate) > new Date() ? "selected" : "current"} period
                          <CardPhraseSuperNumber>
                            {convertNumber(period.creditsConsumed,0)}
                          </CardPhraseSuperNumber>
                        </CardPhraseText>
                      </CardPhrase>
                    }
                    headerRightCollapsed={
                      <CardPhraseCollapsed>
                        <CardPhrase>
                          <CardPhraseText>
                            <CardPhraseSuperNumber>
                            {convertNumber(period.creditsConsumed,0)}
                            </CardPhraseSuperNumber>
                          </CardPhraseText>
                        </CardPhrase>
                      </CardPhraseCollapsed>
                    }
                  >
                    {usage && usage.length > 0 ? 
                      <OzChartLine
                        fnFormatY={(val) => convertNumber(val)}
                        fnFormatValue={(val) => 
                          <MegaTooltip
                            visible={true}
                            title="Plan credits usage"
                            subtitle={getFormattedDate(val.date, false)}
                            value={convertNumber(val.value,0)}
                          />
                        }
                        limits={period.currentPlan.credits !== period.creditsRecharged ?
                          [{
                            value: period.currentPlan.credits,
                            color: SILVERTREE,
                            labelComponent: <LabelComponent><PlanDot /></LabelComponent>
                          },{
                            value: period.creditsRecharged,
                            color: CHETWODEBLUE,
                            labelComponent: <LabelComponent><RechargeIconMini><span className="material-symbols-outlined">nearby</span></RechargeIconMini></LabelComponent>
                          }]
                        :
                          [{
                            value: period.currentPlan.credits,
                            color: CHETWODEBLUE,
                            labelComponent: <LabelComponent><PlanDot /></LabelComponent>
                          }]
                        }
                        style={{
                          lines:{
                            color: CHETWODEBLUE,
                            size: 3
                          },
                          dots:{
                            borderColor: "white",
                            borderSize: 3,
                            color: CHETWODEBLUE,
                            hoverColor: CHETWODEBLUE,
                            size: 6
                          }
                        }}
                        values={usageToGraph(period, usage, true, "credits").map((item) => ({
                          label: <ChartXLabel>{
                            getArrayFromInterval(period.startDate, period.endDate ? period.endDate : new Date()).length > 31 ?
                              (new Date(item.date).getDate() === 1 ? 
                                <>{getFormattedMonth(item.date)}<br/>{new Date(item.date).getFullYear() % 100}</>
                              : null)
                            : 
                              <>{isSameDate(item.date, new Date(), "d") ? "TODAY" : new Date(item.date).getDate()}<br/>{getWeekDay(item.date)[0]}</>
                          }</ChartXLabel>,
                          value: item.value,
                          date: item.date,
                          style: isSameDate(item.date, new Date(), "d") ? {
                            labelColor: SILVERTREE,
                            barColor: SILVERTREE+"26",
                            barColorHover: SILVERTREE+"aa"
                          } : new Date(item.date) > new Date() ? {
                            // background: "#ffffff"
                          } : null
                        }))}
                      />
                    : 
                      <NoLog>No credits usage during this period.</NoLog>    
                    }
                    <br/>
                    <PlanDot />&nbsp; Plan included Credits &nbsp;&nbsp;&nbsp; <RechargeIconMini><span className="material-symbols-outlined">nearby</span></RechargeIconMini>&nbsp; Available Credits
                  </OzCard>
                </OzCol>
              : <OzLoading /> }
            </>
          : null}
        </OzRow>
      </HealthyWrapper>
    </OzContainer>
  )
}

export default Overview;
