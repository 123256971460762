import React, {useState, useEffect, useLayoutEffect, useRef, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {CognitoContext} from '../contexts/CognitoContext';
import {OverlayContext} from './../contexts/OverlayContext';

import ImpersonificationBar from "./layout/ImpersonificationBar";
import Hoverer from "./layout/Hoverer";
import Footer from "./layout/Footer";
import Overlay from "./layout/Overlay";
import PopupNewsletter from "./PopupNewsletter";

import {adminControl} from './../helpers';
import {POPUP} from './../config';

const COLUMN_WIDTH = "72px";
const SIDE_WIDTH = "256px";
const DETAIL_WIDTH = "515px";


const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: calc(100vw - ${props => props.fixSide ? "calc("+COLUMN_WIDTH+" + "+SIDE_WIDTH+")" : COLUMN_WIDTH});
  height: 100%;
  z-index: 1;

  flex-grow: 1;
  flex-shrink: 1;
  background: #F6F7FB;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  overflow: hidden;

  transition: all 200ms ease-in-out;
`;

const ScrollerPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 25px 0px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s;
`;
const Page = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const ScrollerDetail = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${DETAIL_WIDTH};
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s;
  box-shadow: 0 0 10px 1px #4466f20d;
`;
const Detail = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

`;
const Foot = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 60px;
`;

const Layout = ({ section, detail, children }) => {
  const refScrollerPage = useRef();
  const refScrollerDetail = useRef();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(true);
  const [fixSide, setFixSide] = useState(true);
  const {cognito} = useContext(CognitoContext);
  const {overlay, addOverlay} = useContext(OverlayContext);
  
  useLayoutEffect(() => {
    refScrollerDetail.current.scrollTo(0, 0);
  }, [location.pathname]);
  

  useEffect(() => {
    // localStorage.removeItem("ozwolConsolePopup"+POPUP+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
    if(showPopup && !localStorage.getItem("ozwolConsolePopup"+POPUP+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))){
      addOverlay({
        reference: "popupNewsletter",
        type: "popup",
        content: <PopupNewsletter fnClose={() => {
          localStorage.setItem("ozwolConsolePopup"+POPUP+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), true);
        }} />
      });
      setShowPopup(false);
    } 
  }, [addOverlay, showPopup]);

  return (
    <Container>
      {adminControl(cognito) && section !== "insider" ? 
        <Top>
          <ImpersonificationBar />
        </Top>
      : null }
      <Wrapper>
        <Hoverer section={section} onSetFixSide={(val) => setFixSide(val)}/>
        <Content fixSide={fixSide}>
          <ScrollerPage ref={refScrollerPage}>
            <Page>
              {React.cloneElement(children, {refScrollerPage: refScrollerPage})}
            </Page>
            <Foot>
              <Footer />
            </Foot>
          </ScrollerPage>
          <ScrollerDetail ref={refScrollerDetail} style={{maxWidth: detail ? DETAIL_WIDTH : "0px"}}>
            {detail ?
              <Detail>
                {detail}
              </Detail>
            : null }
          </ScrollerDetail>
        </Content>
        {overlay.length > 0 ? 
          overlay.map((item, i) => 
            <Overlay 
              key={i} 
              {...item}
            >
              {item.content}
            </Overlay>
          )
        : null}
      </Wrapper>
    </Container>
  )
}

export default Layout;
