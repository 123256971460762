import {JET} from "@ozwol/webui";

export const BREAKPOINT_XS = 768 - 1;
export const BREAKPOINT_SM = 992 - 1;
export const BREAKPOINT_MD = 1400 - 1;

export const LAYOUT_XS = "XS";
export const LAYOUT_SM = "SM";
export const LAYOUT_MD = "MD";
export const LAYOUT_LG = "LG";

export const PAGESIZE = 20;

export const CDN = "https://cdn.ozwol.com/ozwol/";

export const FORCE_LOGOUT = 2;
export const POPUP = 1;


export const OVERLAY_TIMEOUT_SUCCESS = 2000;
export const OVERLAY_TIMEOUT_ERROR = 10000;

export const PREVIEWABLES_EXTS = ["jpg","jpeg","png","tif","tiff","bmp","gif","webp","avif"];
export const PREVIEWABLE_MAX_SIZE = 100000000; //100MB

export const chartJsDoughnutOptions = {
  responsive: true,
  stacked: false,
  plugins: {
    title: false,
		legend: false,
		tooltip: {
			callbacks: {
        title: function(data) {
          return data[0]["dataset"]["labels"][data[0]["dataIndex"]]//;data["datasets"][0]["data"][tooltipItem["index"]];
        },
        label: function(data) {
          return data["dataset"]["data"][data["dataIndex"]]//;data["datasets"][0]["data"][tooltipItem["index"]];
        }
      },
			displayColors: false,
      backgroundColor: JET,
      titleFontColor: "#FFFFFF",
      bodyFontColor: "#FFFFFF99",
			titleAlign: "center",
			bodyAlign: "center"

      // titleFontSize: 16,
      // bodyFontSize: 14,

		}
  }
};

export const chartJsBarOptions = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    title: {
      display: false
    },
		legend: false,
		tooltip: {
      backgroundColor: JET,
      titleFontColor: "#FFFFFF",
      bodyFontColor: "#FFFFFF99",
			titleAlign: "center"
		}
  },
	maxBarThickness: 6,
	scales: {
		x: {
			stacked: true,
			grid: {
				display: false
			},
			ticks: {
        font: {
          size: 10,
        }
      }
		},	
		y: {
			stacked: true,
			ticks: {
        font: {
          size: 10,
        }
      }
		}
	},
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
    }
  }
};

export const chartJsLineOptions = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false
    },
		legend: false
  },
	scales: {
		x: {
			grid: {
				display: false
			},
      ticks: {
        font: {
          size: 10,
        }
      }
		},
    y: {
			ticks: {
        font: {
          size: 10,
        }
      }
		}
	},
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
    }
  }
};
export const chartJsAreaOptions = {
  responsive: true,
  interaction: {
    mode: 'nearest',
    axis: 'xy',
  },
  plugins: {
    title: {
      display: false
    },
		legend: false,
    tooltip: {
      intersect: false,
      filter: function (data) {
        var value = data.dataset.data[data.dataIndex];
        if (value === 0) {
            return false;
        } else {
            return true;
        }
      }
    }
  },
	scales: {
		x: {
			grid: {
				display: false
			},
			ticks: {
        font: {
          size: 10,
        }
      }
		},
    y: {
      stacked: true,
			ticks: {
        font: {
          size: 10,
        }
      }
    }
	},
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
    }
  }
};