import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import { Line } from 'react-chartjs-2';

import {OzButton, OzContainer, OzRow, OzCol, OzCard, OzTable, OzProjectTag, OzDatepicker, OzSelect} from '@ozwol/webui';


import UsersService from '../../services/UsersService';
import ProjectsService from '../../services/ProjectsService';
import UsagesService from '../../services/UsagesService';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import ChartWrapper from '../../components/ChartWrapper';

import {formatError, changeDate, getIsoDate, getFormattedDate, getFormattedTime, convertNumber, convertSize} from './../../helpers';
import {chartJsAreaOptions} from './../../config';

const RANGE_TODAY = "Daily";
const RANGE_WEEK = "Weekly";
const RANGE_MONTH = "Monthly";
const RANGE_SEMESTER = "Six-Monthly";
const RANGE_YEAR = "Yearly";
const RANGE_CUSTOM = "Custom";

const SUBJECT_CREDITS = "credits";
const SUBJECT_ASSETS = "assets";

const USAGES_UUID_COLUMN = 0;
const USAGES_INDEX_COLUMN = 1;
const USAGES_ASSETS_COLUMN = 2;
const USAGES_CREDITS_COLUMN = 3;
const USAGES_STORAGE_COLUMN = 4;
const USAGES_TRANSFER_COLUMN = 5;

const GRAIN_HOURS = "h";
const GRAIN_DAYS = "d";
const GRAIN_WEEKS = "w";
const GRAIN_MONTHS = "M";

const CONSUMER_ALL = null;
const CONSUMER_SCRIPTS = "SCRIPT";
const CONSUMER_STORAGE = "STORAGE";
const CONSUMER_TRANSFER = "TRANSFER";

const GRAIN_TRANSLATIONS = {
  "h": "hour",
  "d": "day",
  "w": "week",
  "M": "month"
}


const CardPhrase = styled.div`
  display: flex;
  align-items: center;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;

  display: flex;
  align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
`;

const Spacer = styled.div`
  height: 418px
`;

const ButtonsGroup = styled.div`
  display: flex;
  & > :not(:first-child){
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  & > :not(:last-child){
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
  }
  & :first-child > button{ //picker
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
  }
`;


const InsiderTrends = ({refScrollerPage}) => {
  const [start, setStart] = useState(getIsoDate(new Date((new Date()).setUTCHours(0, 0, 0, 0))));
  const [end, setEnd] = useState(null);
  const [consumer, setConsumer] = useState(CONSUMER_SCRIPTS);
  const [hideTrivial, setHideTrivial] = useState(true);
  const [range, setRange] = useState(RANGE_TODAY);
  const [subject, setSubject] = useState(SUBJECT_ASSETS);
  const [grain, setGrain] = useState(GRAIN_HOURS);
  const [projects, setProjects] = useState([]);
  const [usages, setUsages] = useState(null);
  const [users, setUsers] = useState([]);

  const [selected, setSelected] = useState([]);
  
  const [usagesLine, setUsagesLine] = useState(null);

  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    ProjectsService.adminList({"limit": 0}).then((response) => {
      setProjects(response.result);
      setSelected(response.result.map((project => project.uuid)));
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    UsersService.adminList({"limit": 0}).then((response) => {
      setUsers(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    
  }, []);

  

  useEffect(() => {
    let params = {};
    params.grain = grain;
    params.start = start;
    params.consumer = consumer;
    params.hideTrivial = hideTrivial;
    params.end = end;
    UsagesService.listByProjects(params).then((response) => {
      setUsages(response);      
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    
  }, [start, end, grain, consumer, hideTrivial]);

  useEffect(() => {    
    if(projects && usages){
      setUsagesLine({
        labels: [...new Set(usages.dataset.map(set => getFormattedDate(set[USAGES_INDEX_COLUMN])))].map(x => range === RANGE_TODAY ? getFormattedTime(x).slice(0, -3) : getFormattedDate(x, false)),
        datasets: projects.filter(project => usages.dataset.map(set => set[USAGES_UUID_COLUMN]).includes(project.uuid)).map((project) => ({
            uuid: project.uuid,
            label: project.name,
            data: [...new Set(usages.dataset.map(set => set[USAGES_INDEX_COLUMN]))].map(day => 
              usages.dataset.filter(set => set[USAGES_INDEX_COLUMN] === day && set[USAGES_UUID_COLUMN] === project.uuid).reduce((accumulator, currentValue) => {
                return accumulator + currentValue[getColumn(subject, consumer)]
              },0)
            ),
            borderColor: project.color,
            backgroundColor: project.color+"66",
            borderWidth: 2,
            pointRadius: 0,
            // tension: 0.1,
            fill: "stack"
        }))
      });
    }
  }, [subject, projects, usages, grain, start, end, consumer, range]);

  const getColumn = (subject, consumer) => {
    if(subject === SUBJECT_CREDITS){
      return USAGES_CREDITS_COLUMN;
    }else if(consumer === CONSUMER_SCRIPTS || consumer === CONSUMER_ALL){
      return USAGES_ASSETS_COLUMN;
    }else if(consumer === CONSUMER_STORAGE){
      return USAGES_STORAGE_COLUMN;
    }else if(consumer === CONSUMER_TRANSFER){
      return USAGES_TRANSFER_COLUMN;
    }
  }

  return (
    <OzContainer size="extra">
      <Meta title={"Trends"} />
      <PageHeader
        breadcrumbsIcon="price_change"
        breadcrumbsText={["Trends"]}
        refScrollerPage={refScrollerPage}
      />
      <HealthyWrapper error={fetchError} loading={!projects || !users}>
        <OzRow>
          <OzCol widthmd={8}>
            <ButtonsGroup>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={consumer === CONSUMER_ALL}
                onClick={() => {
                  setConsumer(CONSUMER_ALL);
                  setSubject(SUBJECT_CREDITS);
                }}
                noMinWidth
              >
                All
              </OzButton>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={consumer === CONSUMER_SCRIPTS}
                onClick={() => {
                  setConsumer(CONSUMER_SCRIPTS);
                }}
                noMinWidth
              >
                Scripts
              </OzButton>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={consumer === CONSUMER_STORAGE}
                onClick={() => {
                  setConsumer(CONSUMER_STORAGE);
                }}
                noMinWidth
              >
                Storage
              </OzButton>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={consumer === CONSUMER_TRANSFER}
                onClick={() => {
                  setConsumer(CONSUMER_TRANSFER);
                }}
                noMinWidth
              >
                Transfer
              </OzButton>
            </ButtonsGroup>
          </OzCol>
          
          {/* <OzCol widthmd={3}>
            <OzSelect
              width="100%"
              values={[{
                "name": <b>Hours</b>,
                "value": GRAIN_HOURS,
              },{
                "name": <b>Days</b>,
                "value": GRAIN_DAYS,
              },{
                "name": <b>Weeks</b>,
                "value": GRAIN_WEEKS,
              },{
                "name": <b>Months</b>,
                "value": GRAIN_MONTHS,
              }]}
              selected={grain}
              onChange={(val) => {
                setGrain(val);
              }}
            />
          </OzCol>         */}
          <OzCol widthmd={4}>
            <OzSelect
              width="100%"
              values={[{
                "name": <b>Hide trivial</b>,
                "value": true,
              },{
                "name": <b>Show trivial</b>,
                "value": false,
              }]}
              selected={hideTrivial}
              onChange={(val) => {
                setHideTrivial(val);
              }}
            />
          </OzCol>
          <OzCol widthmd={4}> 
            <ButtonsGroup>
              {consumer !== CONSUMER_ALL ? 
                <OzButton
                  width="100%"
                  variant={"push-black"}
                  pushed={subject === SUBJECT_ASSETS}
                  onClick={() => setSubject(SUBJECT_ASSETS)}
                  noMinWidth
                >
                  {consumer === CONSUMER_STORAGE ? 
                    "Storage"
                  : consumer === CONSUMER_TRANSFER ? 
                    "Transfer"
                  : "Assets"}
                </OzButton>
              : null}
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={subject === SUBJECT_CREDITS}
                onClick={() => setSubject(SUBJECT_CREDITS)}
                noMinWidth
              >
                Credits
              </OzButton>
            </ButtonsGroup>
          </OzCol>
          <OzCol widthmd={8}>
          <ButtonsGroup>
              <OzDatepicker
                type="range"
                compact={true}
                buttonLabel={<span className="material-symbols-outlined">calendar_month</span>}
                onChange={(startVal, endVal) => {
                  startVal = new Date(new Date(startVal).toDateString());
                  startVal = new Date(Date.UTC(startVal.getFullYear(), startVal.getMonth(), startVal.getDate(), 0, 0, 0));
                  setStart(getIsoDate(startVal));

                  if(endVal){
                    endVal = new Date(new Date(endVal).toDateString());
                    endVal = new Date(Date.UTC(endVal.getFullYear(), endVal.getMonth(), endVal.getDate(), 23, 59, 59));
                    setEnd(getIsoDate(endVal));
                  }

                  setRange(RANGE_CUSTOM);
                  setGrain(GRAIN_DAYS);

                }}
                startValue={new Date((new Date(start)).setHours(0, 0, 0, 0))}
                endValue={new Date((new Date(end)).setHours(23, 59, 59, 999))}
              />
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={range === RANGE_YEAR}
                onClick={() => {
                  setRange(RANGE_YEAR);
                  setStart(changeDate((new Date((new Date()).setHours(0,0,0,0))), -12, "months"));
                  setEnd(null);
                  setGrain(GRAIN_MONTHS);
                }}
                noMinWidth
              >
                Year
              </OzButton>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={range === RANGE_SEMESTER}
                onClick={() => {
                  setRange(RANGE_SEMESTER);
                  setStart(changeDate((new Date((new Date()).setHours(0,0,0,0))), -6, "months"));
                  setEnd(null);
                  setGrain(GRAIN_WEEKS);
                }}
                noMinWidth
              >
                Semester
              </OzButton>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={range === RANGE_MONTH}
                onClick={() => {
                  setRange(RANGE_MONTH);
                  setStart(changeDate((new Date((new Date()).setHours(0,0,0,0))), -1, "months"));
                  setEnd(null);
                  setGrain(GRAIN_DAYS);
                }}
                noMinWidth
              >
                Month
              </OzButton>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={range === RANGE_WEEK}
                onClick={() => {
                  setRange(RANGE_WEEK);
                  setStart(changeDate((new Date((new Date()).setHours(0,0,0,0))), -7, "days"));
                  setEnd(null);
                  setGrain(GRAIN_DAYS);
                }}
                noMinWidth
              >
                Week
              </OzButton>
              <OzButton
                width="100%"
                variant={"push-black"}
                pushed={range === RANGE_TODAY}
                onClick={() => {
                  setRange(RANGE_TODAY);
                  setStart(getIsoDate(new Date((new Date()).setHours(0,0,0,0))));
                  setEnd(null);
                  setGrain(GRAIN_HOURS);
                }}
                noMinWidth
              >
                Today
              </OzButton>
            </ButtonsGroup>
          </OzCol>
        </OzRow>
        <br/>
        {usages ?
          <OzRow>
            <OzCol widthmd="12">
              <OzCard
                height="100%"
                headerLeft={
                  <>
                    <CardHeaderTitle title={range + " "+(subject === SUBJECT_CREDITS ?
                        "credits"
                      : consumer === CONSUMER_STORAGE ? 
                        "storage"
                      : consumer === CONSUMER_TRANSFER ? 
                        "transfer"
                      : "assets")+" distribution by "+GRAIN_TRANSLATIONS[grain]} />
                  </>
                }
                // headerRight={
                //   <CardPhrase>
                //     <CardPhraseText>
                //       Day credits usage
                //       <CardPhraseSuperNumber>
                //         XXX
                //       </CardPhraseSuperNumber>
                //     </CardPhraseText>
                //   </CardPhrase>
                // }
              >       
                <center>   
                  <ChartWrapper>
                    {usagesLine ? 
                      <Line options={chartJsAreaOptions} data={{
                        labels: usagesLine.labels,
                        datasets: usagesLine.datasets.filter(dataset => selected.includes(dataset.uuid))
                      }} />
                    : 
                      <Spacer />
                    }
                  </ChartWrapper>    
                </center>
              </OzCard>
            </OzCol>
            <OzCol>
            
              
              <OzCard
                height="100%"
                headerLeft={
                  <CardHeaderTitle 
                    title="Consumption of single projects"
                  />
                }
                headerRight={
                  <CardPhrase>
                    <CardPhraseText>
                      Number of projects
                      <CardPhraseSuperNumber>
                        {projects.filter(project => usages.dataset.map(set => set[USAGES_UUID_COLUMN]).includes(project.uuid)).length}
                      </CardPhraseSuperNumber>
                    </CardPhraseText>
                  </CardPhrase>
                }
              >
                <OzTable
                  selectable={true}
                  selected={selected}
                  onSelect={(ids) => {
                    let tempSelected = [...selected]
                    ids.forEach(id => {
                      setSelected(tempSelected);
                      if(!selected.includes(id)){
                        tempSelected.push(id);
                      }else{
                        tempSelected = tempSelected.filter(e => e !== id);
                      }                    
                    });
                    setSelected(tempSelected);
                  }}
                  columns={[
                    "Project",
                    "Owner",
                    consumer === CONSUMER_ALL || consumer === CONSUMER_SCRIPTS ? "Assets" : null,
                    consumer === CONSUMER_ALL || consumer === CONSUMER_STORAGE ? "Storage" : null,
                    consumer === CONSUMER_ALL || consumer === CONSUMER_TRANSFER ? "Transfer" : null,
                    "Credits"
                  ]}
                  values={projects.filter(project => usages.dataset.map(set => set[USAGES_UUID_COLUMN]).includes(project.uuid)).sort((a,b) => 
                    (usages.dataset.filter(set => set[USAGES_UUID_COLUMN] === b.uuid).reduce((accumulator, currentValue) => {
                      return accumulator + currentValue[getColumn(subject, consumer)]
                    },0))
                    -
                    (usages.dataset.filter(set => set[USAGES_UUID_COLUMN] === a.uuid).reduce((accumulator, currentValue) => {
                      return accumulator + currentValue[getColumn(subject, consumer)]
                    },0))
                  ).map((project) => (({  
                    id: project.uuid,
                    values: [
                      <OzProjectTag
                        name={project.name}
                        color={project.color}
                        direction={"horizontal"}
                      />,
                      users.filter((user) => user.uuid === project.ownerUuid).length > 0 ? users.filter((user) => user.uuid === project.ownerUuid)[0].company : "-",
                      consumer === CONSUMER_ALL || consumer === CONSUMER_SCRIPTS ? convertNumber(usages.dataset.filter(set => set[USAGES_UUID_COLUMN] === project.uuid).reduce((accumulator, currentValue) => {
                        return accumulator + currentValue[getColumn(SUBJECT_ASSETS, CONSUMER_SCRIPTS)]
                      },0)) : null,
                      consumer === CONSUMER_ALL || consumer === CONSUMER_STORAGE ? convertSize(usages.dataset.filter(set => set[USAGES_UUID_COLUMN] === project.uuid).reduce((accumulator, currentValue) => {
                        return accumulator + currentValue[getColumn(SUBJECT_ASSETS, CONSUMER_STORAGE)]
                      },0)) : null,
                      consumer === CONSUMER_ALL || consumer === CONSUMER_TRANSFER ? convertSize(usages.dataset.filter(set => set[USAGES_UUID_COLUMN] === project.uuid).reduce((accumulator, currentValue) => {
                        return accumulator + currentValue[getColumn(SUBJECT_ASSETS, CONSUMER_TRANSFER)]
                      },0)) : null,
                      convertNumber(usages.dataset.filter(set => set[USAGES_UUID_COLUMN] === project.uuid).reduce((accumulator, currentValue) => {
                        return accumulator + currentValue[getColumn(SUBJECT_CREDITS)]
                      },0))
                    ]
                  })))}
                />
              </OzCard>
            </OzCol>
          </OzRow>
        : null }
      </HealthyWrapper>
    </OzContainer>
  )
}

export default InsiderTrends;
