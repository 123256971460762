import axios from 'axios';
import {queryParams} from '../helpers';

class UsagesService {

  constructor() {
    const baseURL = process.env.REACT_APP_QUESTDB_URL+"/";
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    this.instance.interceptors.request.use(
      function(config) {
        config.headers["X-Api-Key"] = process.env.REACT_APP_QUESTDB_SEARCH_TOKEN;
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }
  
  listByProjects(params = {
    grain: "hour",
    consumer: true,
    hideTrivial: true,
    start: new Date(),
    end: null
  }) {
    return new Promise((resolve, reject) => {
      const query = {
        query: `SELECT
            project_uuid,
            datetime AS index,
            SUM(assets_processed) AS assets,
            SUM(credit_price) AS credits,
            SUM(storage_in_use) AS storage,
            SUM(transfer_consumed) AS transfer
          FROM ${process.env.REACT_APP_QUESTDB_TABLE} 
          WHERE datetime >= '${params.start}'
            ` + (params.end ? `AND datetime <= '${params.end}' ` : ``) + `
            ` + (params.consumer ? `AND consumer_type = '${params.consumer}'` : ``) + `
            ` + (params.hideTrivial ? `AND trivial = false` : ``) + `
          SAMPLE BY 1${params.grain} FILL(0, 0, 0, 0)`
      };
      this.instance.get('exec'+queryParams(query)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listSingleUsagesByOwners(params = {
    grain: "hour",
    consumer: true,
    hideTrivial: true,
    start: new Date(),
    end: null
  }) {
    return new Promise((resolve, reject) => {
      const query = {
        query: `SELECT
            user_uuid AS owner_uuid, 
            datetime AS index,
            SUM(assets_processed) AS assets,
            SUM(credit_price) AS credits,
            SUM(storage_in_use) AS storage,
            SUM(transfer_consumed) AS transfer
          FROM ${process.env.REACT_APP_QUESTDB_TABLE} 
          WHERE datetime >= '${params.start}'
            ` + (params.end ? `AND datetime <= '${params.end}'` : ``) + `
            ` + (params.consumer ? `AND consumer_type = '${params.consumer}'` : ``) + `
            ` + (params.hideTrivial ? `AND trivial = false` : ``) + `
          SAMPLE BY 1${params.grain} FILL(0, 0, 0, 0);`
      };
      this.instance.get('exec'+queryParams(query)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getCumulativeUsagesByOwner(params = {
    grain: "hour",
    consumer: true,
    hideTrivial: true,
    start: new Date(),
    end: null
  }) {
    return new Promise((resolve, reject) => {
      const query = {
        query: `WITH daily_totals AS (  
          SELECT
            datetime AS index,
            SUM(assets_processed) AS assets,
            SUM(credit_price) AS credits,
            SUM(storage_in_use) AS storage,
            SUM(transfer_consumed) AS transfer
          FROM ${process.env.REACT_APP_QUESTDB_TABLE} 
          WHERE user_uuid = '${params.ownerUuid}'
            AND datetime >= '${params.start}'
            ` + (params.end ? `AND datetime <= '${params.end}'` : ``) + `
            ` + (params.consumer ? `AND consumer_type = '${params.consumer}'` : ``) + `
            ` + (params.hideTrivial ? `AND trivial = false` : ``) + `
          SAMPLE BY 1${params.grain} FILL(0, 0, 0, 0)
        )
        SELECT
          dt1.index,
          SUM(dt2.assets) AS assets,
          SUM(dt2.credits) AS credits,
          SUM(dt2.storage) AS storage,
          SUM(dt2.transfer) AS transfer
        FROM daily_totals dt1
        JOIN daily_totals dt2
          ON dt2.index <= dt1.index
        GROUP BY dt1.index
        ORDER BY dt1.index`
      };
      this.instance.get('exec'+queryParams(query)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service =  new UsagesService();
export default service;