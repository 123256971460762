import axios from 'axios';

class IubendaService {

  constructor() {
    const baseURL = "https://www.iubenda.com/api/";
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getTerms() {
    return new Promise((resolve, reject) => {
      this.instance.get('termini-e-condizioni/45657269/no-markup').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getPrivacy() {
    return new Promise((resolve, reject) => {
      this.instance.get('privacy-policy/45657269/no-markup').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service =  new IubendaService();
export default service;